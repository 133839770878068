import React, { useContext, useState, useEffect } from 'react';
import Navbar from './Navbar';
import './Accountdetails.css';
import { ShopContext } from '../../Context/ShopContext';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontFamily: 'Poppins, sans-serif',
};

const AccountDetails = () => {
  const { userDetails, updateUserDetails } = useContext(ShopContext);
  const { t, i18n } = useTranslation();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [lang, setLang] = useState(i18n.language || 'en'); // Init with current language
  const [phone, setPhone] = useState('');
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // Synchroniser userDetails et initialiser les champs
  useEffect(() => {
    if (userDetails) {
      setFirstname(userDetails.firstname || '');
      setLastname(userDetails.lastname || '');
      setPhone(userDetails.phone || '');
      setLang(userDetails.lang || i18n.language); // Update lang from userDetails
    }
  }, [userDetails]);

  // Synchroniser la langue locale avec la langue de i18n
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  // Fonction pour enregistrer les détails mis à jour
  const handleSave = async () => {
    const response = await updateUserDetails({ firstname, lastname, phone, lang });
    if (response.success) {
      setModalMessage(t('detailsUpdatedSuccess'));
    } else {
      setModalMessage(t('detailsUpdatedFailure'));
    }
    setOpen(true);
  };

  // Changement de langue
  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    i18n.changeLanguage(selectedLang); // Changer la langue dans i18n immédiatement
    setLang(selectedLang); // Mettre à jour l'état local
  };

  // Fermer le modal
  const handleClose = () => setOpen(false);

  return (
    <div className='accountdetails'>
      <div className="accountdetails-left">
        <Navbar />
      </div>
      <div className="accountdetails-right">
        <div className="accountdetails-main">
          <h1>{t('accountDetails')}</h1>
          {userDetails ? (
            <div className="accountdetails-right">
              <p className="accountdetails-email">{userDetails.email}</p>
              <div>
                {t('firstname')}<br/>
                <TextField
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  className='accountdetails-input'
                  variant="outlined"
                  margin="dense"  // Corrected syntax
                />
              </div>
              <div>
                {t('lastname')}<br/>
                <TextField
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  className='accountdetails-input'
                  variant="outlined"
                  margin="dense"  // Corrected syntax
                />
              </div>
              <div>
                {t('address.phonenumber')}<br/>
                <TextField
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className='accountdetails-input'
                  variant="outlined"
                  margin="dense"  // Corrected syntax
                />
              </div>
              <div>
                {t('lang')}<br/>
                <Select
                  value={lang} // Synchroniser la select box avec l'état local lang
                  onChange={handleLangChange} // Mettre à jour la langue lorsqu'on change la valeur
                  className='accountdetails-input'
                  variant="outlined"
                  margin="dense"  // Corrected syntax
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="fr">Français</MenuItem>
                </Select>
              </div>
              <Button onClick={handleSave} variant="contained" className='accountdetails-main-button' sx={{ marginTop: 3, marginBottom: 1 }}>
                {t('save')}
              </Button>
            </div>
          ) : (
            <p>{t('loading')}</p>
          )}
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography>{modalMessage}</Typography>
          <Button onClick={handleClose} className="accountaddress-main-button">{t('close')}</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AccountDetails;
