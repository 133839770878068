import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import cart_icon from '../Assets/cart_icon.png';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getTotalCartItems } = useContext(ShopContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    const path = location.pathname.replace(/^\/(en|fr)/, '');
    navigate(`/${lang}${path}`);
    handleCloseLangMenu();
  };

  const pathToMenu = {
    '/': 'shop',
    '/about': 'about',
    '/login': 'login',
    '/account': 'account',
    '/faq': 'FAQ',
  };

  const isUserLoggedIn = Boolean(localStorage.getItem('auth-token'));

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'primary.main',
        boxShadow: '0 5px 10px -10px #11385a', // Ajout du box-shadow ici
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to={`/${currentLang}/`}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Great Vibes, cursive',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'secondary.main', // Couleur secondaire pour le logo
              textDecoration: 'none',
              fontSize: '2rem !important', // Ajuste la taille de la police
            }}
          >
            LeVampe
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {Object.keys(pathToMenu).map((path) => {
                if ((path === '/login' && isUserLoggedIn) || (path === '/account' && !isUserLoggedIn)) {
                  return null;
                }
                return (
                  <MenuItem key={path} onClick={handleCloseNavMenu}>
                    <Link to={`/${currentLang}${path}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography textAlign="center" sx={{ color: 'secondary.main', fontFamily: 'Poppins, sans-serif' }}>
                        {t(pathToMenu[path])}
                      </Typography>
                    </Link>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to={`/${currentLang}/`}
            sx={{
              ml: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Great Vibes, cursive',
              fontWeight: 600,
              letterSpacing: '.2rem',
              color: 'secondary.main', // Couleur secondaire pour le logo
              textDecoration: 'none',
              fontSize: '1.3rem !important', // Ajuste la taille de la police
            }}
          >
            LeVampe
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {Object.keys(pathToMenu).map((path) => {
              if ((path === '/login' && isUserLoggedIn) || (path === '/account' && !isUserLoggedIn)) {
                return null;
              }
              return (
                <Button
                  key={path}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'secondary.main', display: 'block', fontFamily: 'Poppins, sans-serif' }}
                >
                  <Link to={`/${currentLang}${path}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {t(pathToMenu[path])}
                  </Link>
                </Button>
              );
            })}
          </Box>

          {/* Sélecteur de langue */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={handleOpenLangMenu} sx={{ color: 'secondary.main' }}>
              {currentLang === 'en' ? 'EN' : 'FR'}
            </Button>
            <Menu
              id="lang-menu"
              anchorEl={anchorElLang}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
            >
              <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
              <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
            </Menu>
          </Box>

          {/* Icône du panier */}
          <Link className='nav-login-cart' to={`/${currentLang}/cart`}>
            <img src={cart_icon} alt="Cart" width='32' />
          </Link>
          <div className="nav-cart-count">{getTotalCartItems()}</div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
