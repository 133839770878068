import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CSS/LoginSignup.css';

const LoginSignup = () => {
  const theme = useTheme();
  const [state, setState] = useState('Login');
  const [stateLogin, setStateLogin] = useState('');
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: '',  // Nouveau champ pour la confirmation du mot de passe
    email: '',
    lang: currentLang,
  });

  const [errors, setErrors] = useState({}); // État pour les messages d'erreur
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      lang: i18n.language || 'en',
    }));
  }, [i18n.language]);

  // Fonction pour valider les champs du formulaire
  const validateForm = () => {
    let newErrors = {};

    if (state === 'Signup') {
      if (!formData.firstname.trim()) newErrors.firstname = t('address.firstrequire');
      if (!formData.lastname.trim()) newErrors.lastname = t('address.lastrequire');
      if (!formData.password || formData.password.length < 6) newErrors.password = t('errors.PASSWORD_LENGTH');
      if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = t('errors.PASSWORD_MATCH');  // Vérification du mot de passe
    }
    if (!formData.email.trim()) newErrors.email = t('errors.INVALID_EMAIL');

    setErrors(newErrors);

    // Retourne true si aucun message d'erreur
    return Object.keys(newErrors).length === 0;
  };

  const login = async () => {
    if (!validateForm()) return; // Vérification avant de lancer la requête

    let responseData;
    await fetch(`${backendUrl}/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/form-data',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => (responseData = data));
    if (responseData.success) {
      localStorage.setItem('auth-token', responseData.token);
      window.location.replace("/" + currentLang);
    } else {
      setStateLogin(responseData.errors);
    }
  };

  const signup = async () => {
    if (!validateForm()) return; // Vérification avant de lancer la requête

    let responseData;
    await fetch(`${backendUrl}/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/form-data',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => (responseData = data));
    if (responseData.success) {
      localStorage.setItem('auth-token', responseData.token);
      window.location.replace("/" + currentLang);
    } else {
      setStateLogin(responseData.errorCode);
    }
  };

  return (
    <div className='loginsignup'>
      <Container maxWidth="md" className="loginsignup-container">
        <Typography variant="h4" component="h1" gutterBottom>
          {state}
        </Typography>
        <div className="loginsignup-fields">
          {state === 'Signup' && (
            <>
              <TextField
                label={t('lastname')}
                name="lastname"
                value={formData.lastname}
                onChange={changeHandler}
                fullWidth
                margin="normal"
                sx={{ width: '80%', height: '30px', marginBottom: '40px' }}
                error={!!errors.lastname}
                helperText={errors.lastname}
              />
              <TextField
                label={t('firstname')}
                name="firstname"
                value={formData.firstname}
                onChange={changeHandler}
                fullWidth
                margin="normal"
                sx={{ width: '80%', height: '30px', marginBottom: '40px' }}
                error={!!errors.firstname}
                helperText={errors.firstname}
              />
            </>
          )}
          <TextField
            label={t('yourmail')}
            name="email"
            type="email"
            value={formData.email}
            onChange={changeHandler}
            fullWidth
            margin="normal"
            sx={{ width: '80%', height: '30px', marginBottom: '40px' }}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label={t('password')}
            name="password"
            type="password"
            value={formData.password}
            onChange={changeHandler}
            fullWidth
            margin="normal"
            sx={{ width: '80%', height: '30px', marginBottom: '40px' }}
            error={!!errors.password}
            helperText={errors.password}
          />
          {state === 'Signup' && (
            <TextField
              label={t('confirmpassword')}
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={changeHandler}
              fullWidth
              margin="normal"
              sx={{ width: '80%', height: '30px', marginBottom: '16px' }}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            state === 'Login' ? login() : signup();
          }}
          fullWidth
          sx={{ width: '80%', height: '30px', mt: 2 }}
        >
          {t('continue')}
        </Button>
        {state === 'Signup' ? (
          <Typography className="loginsignup-login">
            <span onClick={() => { setState('Login'); }} style={{ color: theme.palette.secondary.main, cursor: 'pointer' }}>
              {t('alreadyaccount')}{' '}?{' '}
              {t('clickhere')}
            </span>
          </Typography>
        ) : (
          <Typography className="loginsignup-login">
            <span onClick={() => { setState('Signup'); }} style={{ color: theme.palette.secondary.main, cursor: 'pointer' }}>
              {t('createaccount')}{' '} - 
              &nbsp;{t('clickhere')}
            </span>
          </Typography>
        )}
        {stateLogin && (
          <div className="errorlog">
            {t(`errors.${stateLogin}`)}
          </div>
        )}
      </Container>
    </div>
  );
};

export default LoginSignup;
