import React, { useContext, useState, useEffect, useCallback } from 'react';
import Navbar from './Navbar';
import { ShopContext } from '../../Context/ShopContext';
import './Accountaddress.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import ship from '../Assets/ship';  // Importation de la liste des pays

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontFamily: 'Poppins, sans-serif',
};

const Accountaddress = () => {
  const { userDetails } = useContext(ShopContext);
  const { t } = useTranslation();

  const [shippingAddress, setShippingAddress] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [sameAsShipping, setSameAsShipping] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errors, setErrors] = useState({ shipping: {}, billing: {} });
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Utilisation de useCallback pour éviter que la fonction soit recréée à chaque rendu
  const fetchAddresses = useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/getaddresses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': `${localStorage.getItem('auth-token')}`,
        },
      });
      const data = await response.json();
      console.log("Fetched addresses:", data);

      let shipping = { country: 'France' }; // Valeur par défaut si l'adresse n'existe pas
      let billing = { country: 'France' };  // Valeur par défaut si l'adresse n'existe pas
      let sameAs = true;

      data.forEach((address) => {
        if (address.addressType === 0) {
          shipping = address;
        } else {
          billing = address;
          sameAs = false;
        }
      });

      setShippingAddress(shipping);
      setBillingAddress(billing);
      setSameAsShipping(sameAs);
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  }, [backendUrl]);  // Dépendance unique sur backendUrl

  // useEffect pour appeler fetchAddresses quand userDetails change
  useEffect(() => {
    if (userDetails) {
      fetchAddresses();
    }
  }, [userDetails, fetchAddresses]);

  // useEffect pour synchroniser l'adresse de facturation avec celle de livraison
  useEffect(() => {
    if (sameAsShipping) {
      setBillingAddress(shippingAddress);
    }
  }, [sameAsShipping, shippingAddress]);

  // Gestion des changements de la checkbox "Same as Shipping"
  const handleCheckboxChange = () => {
    setSameAsShipping(prev => !prev);
  };

  // Gestion des changements des champs d'adresse
  const handleAddressChange = (e, type) => {
    const { name, value } = e.target;
    if (type === 'shipping') {
      setShippingAddress(prev => {
        const updated = { ...prev, [name]: value };
        if (sameAsShipping) {
          setBillingAddress(updated);
        }
        return updated;
      });
    } else {
      setBillingAddress(prev => ({ ...prev, [name]: value }));
    }
  };

  const validateAddresses = () => {
    let tempErrors = { shipping: {}, billing: {} };

    if (!shippingAddress.street1) tempErrors.shipping.street1 = t('address.streetrequire');
    if (!shippingAddress.postalcode) tempErrors.shipping.postalcode = t('address.postalrequire');
    if (!shippingAddress.city) tempErrors.shipping.city = t('address.cityrequire');
    if (!shippingAddress.country) tempErrors.shipping.country = t('address.countryrequire');

    if (!sameAsShipping) {
      if (!billingAddress.street1) tempErrors.billing.street1 = t('address.streetrequire');
      if (!billingAddress.postalcode) tempErrors.billing.postalcode = t('address.postalrequire');
      if (!billingAddress.city) tempErrors.billing.city = t('address.cityrequire');
      if (!billingAddress.country) tempErrors.billing.country = t('address.countryrequire');
    }

    setErrors(tempErrors);

    return !Object.keys(tempErrors.shipping).length && (!sameAsShipping || !Object.keys(tempErrors.billing).length);
  };

  const saveAddresses = async () => {
    if (!validateAddresses()) return;

    try {
      console.log("Saving shipping address:", shippingAddress);
      const response = await fetch(`${backendUrl}/updateaddress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': `${localStorage.getItem('auth-token')}`,
        },
        body: JSON.stringify({ ...shippingAddress, addressType: 0 }),
      });

      if (response.ok) {
        if (sameAsShipping) {
          console.log("Deleting billing address");
          await fetch(`${backendUrl}/deleteaddress`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': `${localStorage.getItem('auth-token')}`,
            },
            body: JSON.stringify({ addressType: 1 }),
          });
        } else {
          console.log("Saving billing address:", billingAddress);
          const billingResponse = await fetch(`${backendUrl}/updateaddress`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': `${localStorage.getItem('auth-token')}`,
            },
            body: JSON.stringify({ ...billingAddress, addressType: 1 }),
          });

          if (!billingResponse.ok) throw new Error('Billing address update failed');
        }
        setModalMessage(t('address.saveAddressesSuccess'));
      } else {
        throw new Error('Shipping address update failed');
      }
    } catch (error) {
      setModalMessage(t('address.saveAddressesFailure'));
      console.error("Failed to save addresses:", error);
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className='accountaddress'>
      <div className="accountaddress-left">
        <Navbar />
      </div>
      <div className="accountaddress-right">
        <div className="accountaddress-main">
          {userDetails ? (
            <div className="accountaddress-container">
              <div className="address-section address-section-left">
                <h1>{t('address.shipping')}</h1>
                <TextField
                  label={t('address.street1')}
                  name="street1"
                  value={shippingAddress.street1 || ''}
                  onChange={(e) => handleAddressChange(e, 'shipping')}
                  placeholder={t('address.street1')}
                  margin="normal"
                  error={!!errors.shipping.street1}
                  helperText={errors.shipping.street1}
                />
                <TextField
                  label={t('address.street2')}
                  name="street2"
                  value={shippingAddress.street2 || ''}
                  onChange={(e) => handleAddressChange(e, 'shipping')}
                  placeholder={t('address.street2')}
                  margin="normal"
                />
                <TextField
                  label={t('address.postalcode')}
                  name="postalcode"
                  value={shippingAddress.postalcode || ''}
                  onChange={(e) => handleAddressChange(e, 'shipping')}
                  placeholder={t('address.postalcode')}
                  margin="normal"
                  error={!!errors.shipping.postalcode}
                  helperText={errors.shipping.postalcode}
                />
                <TextField
                  label={t('address.city')}
                  name="city"
                  value={shippingAddress.city || ''}
                  onChange={(e) => handleAddressChange(e, 'shipping')}
                  placeholder={t('address.city')}
                  margin="normal"
                  error={!!errors.shipping.city}
                  helperText={errors.shipping.city}
                />
                <FormControl margin="normal">
                  <InputLabel>{t('address.country')}</InputLabel>
                  <Select
                    name="country"
                    value={shippingAddress.country || 'France'}
                    onChange={(e) => handleAddressChange(e, 'shipping')}
                  >
                    {ship.map((country) => (
                      <MenuItem key={country.id} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.shipping.country && (
                    <Typography color="error">{errors.shipping.country}</Typography>
                  )}
                </FormControl>
              </div>
              <div className="address-section address-section-right">
                <h1>{t('address.billing')}</h1>
                <div className="same-ship">
                  <input
                    type="checkbox"
                    checked={sameAsShipping}
                    onChange={handleCheckboxChange}
                  />
                  <label> {t('address.sameAsShipping')}</label>
                </div>
                <TextField
                  label={t('address.street1')}
                  name="street1"
                  value={billingAddress.street1 || ''}
                  onChange={(e) => handleAddressChange(e, 'billing')}
                  placeholder={t('address.street1')}
                  margin="normal"
                  InputProps={{
                    readOnly: sameAsShipping,
                  }}
                  error={!!errors.billing.street1}
                  helperText={errors.billing.street1}
                />
                <TextField
                  label={t('address.street2')}
                  name="street2"
                  value={billingAddress.street2 || ''}
                  onChange={(e) => handleAddressChange(e, 'billing')}
                  placeholder={t('address.street2')}
                  margin="normal"
                  InputProps={{
                    readOnly: sameAsShipping,
                  }}
                />
                <TextField
                  label={t('address.postalcode')}
                  name="postalcode"
                  value={billingAddress.postalcode || ''}
                  onChange={(e) => handleAddressChange(e, 'billing')}
                  placeholder={t('address.postalcode')}
                  margin="normal"
                  InputProps={{
                    readOnly: sameAsShipping,
                  }}
                  error={!!errors.billing.postalcode}
                  helperText={errors.billing.postalcode}
                />
                <TextField
                  label={t('address.city')}
                  name="city"
                  value={billingAddress.city || ''}
                  onChange={(e) => handleAddressChange(e, 'billing')}
                  placeholder={t('address.city')}
                  margin="normal"
                  InputProps={{
                    readOnly: sameAsShipping,
                  }}
                  error={!!errors.billing.city}
                  helperText={errors.billing.city}
                />
                <FormControl margin="normal">
                  <InputLabel>{t('address.country')}</InputLabel>
                  <Select
                    name="country"
                    value={billingAddress.country || 'France'}
                    onChange={(e) => handleAddressChange(e, 'billing')}
                    inputProps={{
                      readOnly: sameAsShipping,
                    }}
                  >
                    {ship.map((country) => (
                      <MenuItem key={country.id} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.billing.country && (
                    <Typography color="error">{errors.billing.country}</Typography>
                  )}
                </FormControl>
              </div>
              <div className="addresssave">
                <Button className="accountaddress-main-button" sx={{ marginTop: 3, marginBottom: 1 }} onClick={saveAddresses}>
                  {t('save')}
                </Button>
              </div>
            </div>
          ) : (
            <p>{t('address.pleaseLogIn')}</p>
          )}
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {modalMessage}
              </Typography>
              <Button className="accountaddress-main-button" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Accountaddress;
