import React from 'react'
import CartItems from '../Components/CartItems/CartItems'
import Footer from '../Components/Footer/Footer'

const Cart = () => {
  return (
    <div>
      <CartItems/>
      <Footer></Footer>
    </div>
  )
}

export default Cart