import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importation pour la traduction
import { ShopContext } from '../../Context/ShopContext';
import amppng256 from '../Assets/amp2.png';
import './ProcessValidation.css';

const ProcessValidation = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasValidated, setHasValidated] = useState(false); // Nouveau state pour vérifier si la validation a été faite

  const { t } = useTranslation(); // Utilisation de la traduction
  const { clearCart } = useContext(ShopContext);

  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en';
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    if (!hasValidated) { // Vérifie si la validation a déjà eu lieu
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');

      fetch(`${backendUrl}/session-status?session_id=${sessionId}`, {
        headers: {
          'auth-token': localStorage.getItem('auth-token') // Ajoute le token dans le header Authorization
        }
      })
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
          setOrderId(data.orderId);
          setHasValidated(true); // Met à jour l'état pour indiquer que la validation a été faite
          setLoading(false);
          clearCart();
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données:', error);
          setLoading(false);
        });
    }
  }, [hasValidated]);

  const handleContactSubmit = (event) => {
    event.preventDefault();
    alert(t('processValidation.submitAlert'));
  };

  return (
    <div className='processvalidation-main'>
      {loading ? (
        <div className="loading-container">
          <CircularProgress /> 
        </div>
      ) : status === 'complete' ? (
        <>
          <h1>{t('processValidation.completeTitle')}</h1>
          <h2>{t('processValidation.completeSubtitle', { orderId })}</h2>
          <p>{t('processValidation.confirmationEmail', { customerEmail })}</p>
          <p>{t('processValidation.trackOrder')} <Link to={`/${currentLang}/accountorders`}>{t('processValidation.here')}</Link>.</p>
          <div><img src={amppng256} alt="AMP Logo" /></div>
        </>
      ) : status === 'already_processed' ? (
        <>
          <h1>{t('processValidation.alreadyProcessedTitle')}</h1>
          <h2>{t('processValidation.alreadyProcessedSubtitle', { orderId })}</h2>
          <p>{t('processValidation.trackOrder')} <Link to={`/${currentLang}/accountorders`}>{t('processValidation.here')}</Link>.</p>
          <div><img src={amppng256} alt="AMP Logo" /></div>
        </>
      ) : (
        <>
          <h1>{t('processValidation.errorTitle')}</h1>
          <p>{t('processValidation.errorMessage')}</p>
          <form onSubmit={handleContactSubmit}>
            <label>
              {t('processValidation.emailLabel')}
              <input type="email" name="email" value={customerEmail} readOnly />
            </label>
            <label>
              {t('processValidation.messageLabel')}
              <textarea name="message" required placeholder={t('processValidation.messagePlaceholder')}></textarea>
            </label>
            <button type="submit">{t('processValidation.submitButton')}</button>
          </form>
          <div><img src={amppng256} alt="AMP Logo" /></div>
        </>
      )}
    </div>
  );
};

export default ProcessValidation;
