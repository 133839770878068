import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff', // Couleur principale personnalisée
    },
    secondary: {
      main: '#0b2946', // Couleur secondaire personnalisée
    },
    error: {
      main: '#C5001A', // Couleur tertiaire personnalisée
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    h6: {
      fontFamily: '"Great Vibes", cursive',
      fontSize: '2.2rem', // Taille de police pour h6 (logo)
      fontWeight:'600',
    },
    button: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '1rem', // Taille de police pour les boutons
    },
    body1: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '1rem', // Taille de police pour le corps du texte
    },
    body2: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '0.875rem', // Taille de police pour le corps du texte secondaire
    },
  },
});

export default theme;
