import React from 'react'
import Footer from '../Components/Footer/Footer'
import ContactDisplay from '../Components/ContactDisplay/ContactDisplay'

const Contact = () => {
  return (
    <div><ContactDisplay></ContactDisplay>
<Footer></Footer>
    </div>
  )
}

export default Contact