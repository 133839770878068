import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async'; // Import du HelmetProvider

import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Shop from './Pages/Shop';
import About from './Pages/About';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import LoginSignup from './Pages/LoginSignup';
import Account from './Pages/Account';
import Accountdetails from './Components/Account/Accountdetails';
import Accountaddress from './Components/Account/Accountaddress';
import Accountorders from './Components/Account/Accountorders';
import Protectedroute from './Components/ProtectedRoute/ProtectedRoute';
import Checkout from './Pages/Checkout';
import CheckoutValidation from './Pages/CheckoutValidation';
import CheckoutPaymnt from './Pages/CheckoutPaymnt';
import Faq from './Pages/Faq';
import Cgv from './Pages/Cgv';
import CookieBanner from './Components/CookieBanner/CookieBanner'; // Import de la bannière cookies
import MentionsLegales from './Pages/MentionsLegales';
import Contact from './Pages/Contact';

const AppContent = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const lang = pathParts[1];
  
    // Déterminer la langue du navigateur
    const userLang = navigator.language || navigator.userLanguage;
    const defaultLang = userLang.startsWith('fr') ? 'fr' : 'en';
    //console.log(navigator.language);
    //console.log(navigator.userLanguage);
    //console.log(userLang);
    // Changer la langue si elle est valide
    if (lang && ['en', 'fr'].includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      // Rediriger vers la langue par défaut du navigateur si la langue n'est pas valide
      navigate(`/${defaultLang}`, { replace: true });
    }
  }, [location, i18n, navigate]);
  
  // Ajouter un useEffect pour changer la langue dans la balise <html>
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]); // Se déclenche chaque fois que la langue est modifiée

  return (
    <>
      <Navbar />
      <CookieBanner /> {/* Ajouter la bannière cookie */}
      <Routes>
        <Route path='/' element={<Navigate to='/en' />} />
        <Route path='/:lng' element={<Shop />} />
        <Route path='/:lng/about' element={<About />} />
        <Route path='/:lng/contact' element={<Contact />} />
        <Route path='/:lng/cgv' element={<Cgv />} />
        <Route path='/:lng/mentions' element={<MentionsLegales />} />
        <Route path='/:lng/product/:productId' element={<Product />} />
        <Route path='/:lng/cart' element={<Cart />} />
        <Route path='/:lng/login' element={<LoginSignup />} />
        <Route path='/:lng/account' element={<Protectedroute element={<Account />} />} />
        <Route path='/:lng/accountdetails' element={<Protectedroute element={<Accountdetails />} />} />
        <Route path='/:lng/accountaddress' element={<Protectedroute element={<Accountaddress />} />} />
        <Route path='/:lng/accountorders' element={<Protectedroute element={<Accountorders />} />} />
        <Route path='/:lng/checkout' element={<Protectedroute element={<Checkout />} />} />
        <Route path='/:lng/checkoutpayment' element={<Protectedroute element={<CheckoutPaymnt />} />} />
        <Route path='/:lng/checkoutvalidation' element={<Protectedroute element={<CheckoutValidation />} />} />
        <Route path='/:lng/faq' element={<Faq />} />
        <Route path='*' element={<Navigate to='/en' />} />
      </Routes>
    </>
  );
};

const App = () => (
  <HelmetProvider>
  <BrowserRouter>
    <AppContent />
  </BrowserRouter>
  </HelmetProvider>
);

export default App;
