import React from 'react';
import './MentionsDisplay.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MentionsDisplay = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  return (
    <div className='mentions-main'>
      <h1>{t('mentions.title')}</h1>

      <h2>{t('mentions.generalInfo.heading')}</h2>
      <p>{t('mentions.generalInfo.companyName')}</p>
      <p>{t('mentions.generalInfo.legalForm')}</p>
      <p>{t('mentions.generalInfo.address')}</p>
      <p>{t('mentions.generalInfo.siren')}</p>
      <p>{t('mentions.generalInfo.publicationManager')}</p>
      <p>{t('mentions.generalInfo.hosting')}</p>
      <p>{t('mentions.generalInfo.hostingDetails')}</p>

      <hr className='mentions-hr' />

      <h2>{t('mentions.tva.heading')}</h2>
      <p>{t('mentions.tva.description')}</p>

      <h2>{t('mentions.cgv.heading')}</h2>
      <p>{t('mentions.cgv.description')} <Link to={`/${currentLang}/cgv`}>{t('processValidation.here')}</Link></p>

      <h2>{t('mentions.dataProtection.heading')}</h2>
      <p>{t('mentions.dataProtection.description')}</p>

      <h2>{t('mentions.cookies.heading')}</h2>
      <p>{t('mentions.cookies.description')}</p>

      <h2>{t('mentions.intellectualProperty.heading')}</h2>
      <p>{t('mentions.intellectualProperty.description')}</p>

      <h2>{t('mentions.responsibility.heading')}</h2>
      <p>{t('mentions.responsibility.description')}</p>

      <h2>{t('mentions.applicableLaw.heading')}</h2>
      <p>{t('mentions.applicableLaw.description')}</p>

      <h2>{t('mentions.contact.heading')}</h2>
      <p>{t('mentions.contact.description')}</p>
    </div>
  );
};

export default MentionsDisplay;
