import React, { useContext, useEffect, useState, useRef } from 'react';
import './ProductDisplay.css';
import { Button, Modal, Box } from '@mui/material';
import { ShopContext } from '../../Context/ShopContext';
import { useTranslation } from 'react-i18next';


const ProductDisplay = (props) => {
  const { t } = useTranslation();  // Ajout de la fonction de traduction
  const { product } = props;
  const { addToCart } = useContext(ShopContext);
  const [open, setOpen] = useState(false);
  const [stock, setStock] = useState(null);
  const fetchedRef = useRef(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
  useEffect(() => {
    if (fetchedRef.current) return;

    fetchedRef.current = true;

    const fetchStock = async () => {
      try {
        const response = await fetch(`${backendUrl}/product/${product.idstore}/stock`);
        const data = await response.json();
        if (data.success) {
          setStock(data.stock);
        } else {
          console.error('Failed to fetch stock');
        }
      } catch (error) {
        console.error('Error fetching stock:', error);
      }
    };

    fetchStock();

  }, [product.idstore,backendUrl]);

  const handleAddToCart = () => {
    addToCart(product.idstore);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className='productdisplay'>
      <div className="productdisplay-left">
        <div className="productdisplay-img">
          <img className='productdisplay-main-img' src={`${frontendUrl}/${product.image1}`} alt={t('productDisplay.productImageAlt')} />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{product.name}</h1><hr />
        <div className="productdisplay-right-stars">
          {t('productDisplay.freeShipping')}
        </div>
        <div className="productdisplay-right-prices">
          <div className="productdisplay-right-new">{product.pricettc} €</div>
          <div className="productdisplay-right-stock">
            {stock !== null ? (
              stock > 0 ? t('productDisplay.inStock') : t('productDisplay.outOfStock')
            ) : t('productDisplay.loading')}
          </div>
        </div>

        <Button
          variant="contained"
          onClick={handleAddToCart}
          sx={{ width: 250, fontSize: { xs: 14, sm: 16, md: 18 }, fontWeight: 600 }}
        >
          {t('productDisplay.addToCart')}
        </Button>
        <div className='productdisplay-right-category'>
          <h2>{t('productDisplay.includes')}</h2>
          <ul>
            {t('productDisplay.includesItems', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <h2>{t('productDisplay.functions')}</h2>
          <ul>
            {t('productDisplay.functionsItems', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box className="addtocartbox">
            {t('productDisplay.productAddedToCart')}
          <Button sx={{ marginTop: 2 }} onClick={handleClose} variant="contained">{t('productDisplay.close')}</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ProductDisplay;
