import React from 'react';
import { useTranslation } from 'react-i18next';
import fs5 from '../Assets/decors002.jpg'
import fs7 from '../Assets/decors3_filtered.jpg'
import fs6 from '../Assets/decors01.jpg'

import './AboutDisplay.css';

const AboutDisplay = () => {
  const { t } = useTranslation();

  return (
    <div className='aboutdisplay-main'>
      
      <div className="aboutdisplay-right">
        <div className="aboutdisplay-mainright">
      <h1>{t('title')}</h1>
        <h2>{t('subtitle1')}</h2>
        <img src={fs6} alt=""  className='aboutdisplay-imgped' />
        <p>{t('content1')}</p>
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <p>{t('content2')}</p>
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <h2><b>{t('content3')}</b></h2>
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <p>{t('content4')}</p>
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <p className='ventilbt'>{t('content5')}</p>
        </div>
        <hr />
        
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <p>{t('content8')}</p>
        <div className="fs-list"><br/><img src={fs7} alt=""  className='aboutdisplay-imgped' /></div>
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <div className="aboutdisplay-altmain"><hr /></div>
        
        <p className='aboutdisplay-subtitle ventilbt'></p>
        <h1>{t('content10')}</h1><br/>
        <h2>{t('content9')}</h2>
        <div className="fs-list"><br/><img src={fs5} alt=""  className='aboutdisplay-imgped' /></div>
        <div className="fs-list"><br/>
        <hr />
        <iframe className='youtube-video' src="https://www.youtube.com/embed/Jrk8Pwwozi8?si=o4iE66_ezniQTOqv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.neural')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/Wv-CrGBFg-U?si=hdZ4KozhxKWeWHFB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.amplitube')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/JDOIl7-Cdds?si=now4YGOvZO_FAQ1T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.fatblob')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/2U0lOXEfy5U?si=m8USP5lX6FZ6Rtzj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  
        </div>
        
      </div>
    </div>
  );
}

export default AboutDisplay;
