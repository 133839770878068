import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  // Extraire la langue de l'URL
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en'; // 'en' par défaut si aucune langue n'est trouvée

  return (
    <div className='account-navbar'>
      <Link to={`/${currentLang}/accountdetails`}>
        <Button variant="contained" className='account-navbar-button'>
          {currentLang === 'fr' ? 'Détails du compte' : 'Account details'}
        </Button>
      </Link>
      <Link to={`/${currentLang}/accountaddress`}>
        <Button variant="contained" className='account-navbar-button'>
          {currentLang === 'fr' ? 'Carnet d\'adresses' : 'Address book'}
        </Button>
      </Link>
      <Link to={`/${currentLang}/accountorders`}>
        <Button variant="contained" className='account-navbar-button'>
          {currentLang === 'fr' ? 'Commandes' : 'Orders'}
        </Button>
      </Link>
      <Button 
        variant="contained" 
        className='account-navbar-button' 
        onClick={() => { localStorage.removeItem('auth-token'); window.location.replace(`/${currentLang}/`); }}
      >
        {currentLang === 'fr' ? 'Déconnexion' : 'Logout'}
      </Button>
    </div>
  )
}

export default Navbar;
