import React from 'react'
import CgvDisplay from '../Components/CgvDisplay/cgvdisplay.jsx'
import Footer from '../Components/Footer/Footer'

const Cgv = () => {
  return (
    <div>
      <CgvDisplay></CgvDisplay>
      <Footer></Footer>
    </div>
  )
}

export default Cgv