import React from 'react';
import { useTranslation } from 'react-i18next';
import './cgvdisplay.css';

const CgvDisplay = () => {
  const { t } = useTranslation();

  return (
    <div className='cgvdisplay-main'>
      <h1>{t('cgv.title')}</h1>

      <h3>{t('cgv.section1.title')}</h3>
      <p>{t('cgv.section1.companyName')}</p>
      <p>{t('cgv.section1.siret')}</p>
      <p>{t('cgv.section1.address')}</p>
      <p>{t('cgv.section1.contactEmail')}</p>
      <p>{t('cgv.section1.intro')}</p>

      <h3>{t('cgv.section2.title')}</h3>
      <p>{t('cgv.section2.description')}</p>

      <h3>{t('cgv.section3.title')}</h3>
      <p>{t('cgv.section3.ordering')}</p>
      <p>{t('cgv.section3.payment')}</p>
      <p>{t('cgv.section3.orderConfirmation')}</p>

      <h3>{t('cgv.section4.title')}</h3>
      <p>{t('cgv.section4.deliveryAreas')}</p>
      <ul className='cgv-listcountry'>
        {t('cgv.section4.countries', { returnObjects: true }).map((country, index) => (
          <li key={index}>{country}</li>
        ))}
      </ul>
      <h4>{t('cgv.section4.shippingCosts.title')}</h4>
      <p>{t('cgv.section4.shippingCosts.freeInFrance')}</p>
      <p>{t('cgv.section4.shippingCosts.otherCountries')}</p>
      <p>{t('cgv.section4.deliveryTimes')}</p>
      <p>{t('cgv.section4.geographicalRestrictions')}</p>

      <h3>{t('cgv.section5.title')}</h3>
      <p>{t('cgv.section5.returns')}</p>
      <p>{t('cgv.section5.returnCosts')}</p>
      <p>{t('cgv.section5.rightOfWithdrawal')}</p>

      <h3>{t('cgv.section6.title')}</h3>
      <p>{t('cgv.section6.warranty')}</p>

      <h3>{t('cgv.section7.title')}</h3>
      <p>{t('cgv.section7.dataProtection')}</p>
      <p>{t('cgv.section7.privacyPolicy')}</p>

      <h3>{t('cgv.section8.title')}</h3>
      <p>{t('cgv.section8.intellectualProperty')}</p>

      <h3>{t('cgv.section9.title')}</h3>
      <p>{t('cgv.section9.sav')}</p>
      <p>{t('cgv.section9.complaints')}</p>

      <h3>{t('cgv.section10.title')}</h3>
      <p>{t('cgv.section10.applicableLaw')}</p>
    </div>
  );
};

export default CgvDisplay;
