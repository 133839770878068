const ship = [
  { id: 1, name: "France" },
  { id: 2, name: "Andorra" },
  { id: 3, name: "Monaco" },
  { id: 4, name: "Poland" },
  { id: 5, name: "Germany" },
  { id: 6, name: "United Kingdom" },
  { id: 7, name: "Italy" },
  { id: 8, name: "Spain" },
  { id: 9, name: "Belgium" },
  { id: 10, name: "Sweden" },
  { id: 11, name: "Czech Republic" },
  { id: 12, name: "Portugal" },
  { id: 13, name: "Austria" },
  { id: 14, name: "Switzerland" },
  { id: 15, name: "Croatia" },
  { id: 16, name: "Luxembourg" },
];

export default ship;
