import React from 'react'
import Checkoutbox from '../Components/CheckoutBox/Checkoutbox'

const Checkout = () => {
  return (
    <div>
      <Checkoutbox></Checkoutbox>
    </div>
  )
}

export default Checkout