import React, { useEffect, useState, useRef } from 'react';
import './Item.css';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Item = (props) => {
  const location = useLocation(); // Utiliser useLocation pour obtenir l'URL

  // Extraire la langue de l'URL
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en'; // 'en' par défaut si aucune langue n'est trouvée

  const [stock, setStock] = useState(null);
  const fetchedRef = useRef(false);
  const { t } = useTranslation();
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (fetchedRef.current) return;

    fetchedRef.current = true;

    const fetchStock = async () => {
      try {
        const response = await fetch(`${backendUrl}/product/${props.id}/stock`);
        const data = await response.json();
        if (data.success) {
          setStock(data.stock);
        } else {
          console.error('Failed to fetch stock');
        }
      } catch (error) {
        console.error('Error fetching stock:', error);
      }
    };

    fetchStock();
  }, [props.id]);

  return (
    <div className='item'>
      <Link to={`/${currentLang}/product/${props.id}`}>
        <img onClick={() => window.scrollTo(0, 0)} src={`${frontendUrl}/${props.image}`} alt={props.name} />
      </Link>
      <p>{props.name}</p>
      <div className="item-prices">
        <div className="item-price-new">
          {props.new_price} €
        </div>
        <div className="item-stock">
          {stock !== null ? (
            stock > 0 ? t('productDisplay.inStock') : t('productDisplay.outOfStock')
          ) : 'Loading...'}
        </div>
      </div>
    </div>
  );
}

export default Item;
