import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 
import { Navigate, useLocation } from 'react-router-dom';

const Account = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en'; // 'en' par défaut si aucune langue n'est trouvée
  const { t, i18n } = useTranslation(); // Utilise le hook pour accéder aux traductions
  const canonicalUrl = `${window.location.origin}/${i18n.language}/account`;

  return <div>
            <Helmet>
        <title>{t('seo.account_title')}</title>
        <meta name="description" content={t('seo.account_description')} />
        <meta name="keywords" content={t('seo.account_keywords')} />
        <link rel="canonical" href={canonicalUrl} /> 
      </Helmet>
    <Navigate to={`/${currentLang}/accountdetails`} />
    </div>;
};

export default Account;
