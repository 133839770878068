import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CookieBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [language, setLanguage] = useState('en'); // Langue par défaut
  const location = useLocation();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsBannerVisible(true); // Affiche la bannière si aucun consentement n'est donné
    }

    // Détecter la langue à partir de l'URL
    const pathParts = location.pathname.split('/');
    const lang = pathParts[1];
    if (lang === 'fr' || lang === 'en') {
      setLanguage(lang); // Met à jour la langue en fonction de l'URL
    }
  }, [location]);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsBannerVisible(false); // Cache la bannière après consentement
  };

  const rejectCookies = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsBannerVisible(false); // Cache la bannière après rejet
  };

  if (!isBannerVisible) {
    return null; // Ne rien afficher si la bannière n'est pas nécessaire
  }

  // Traductions des textes en fonction de la langue
  const bannerText = language === 'fr' 
    ? 'Ce site utilise des cookies pour améliorer votre expérience. Acceptez-vous les cookies ?'
    : 'This site uses cookies to improve your experience. Do you accept cookies?';
  
  const acceptText = language === 'fr' ? 'Accepter' : 'Accept';
  const rejectText = language === 'fr' ? 'Refuser' : 'Reject';

  return (
    <div style={bannerStyle}>
      <p>{bannerText}</p>
      <button onClick={acceptCookies} style={buttonStyle}>{acceptText}</button>
      <button onClick={rejectCookies} style={buttonStyle}>{rejectText}</button>
    </div>
  );
};

const bannerStyle = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: 'white',
  padding: '20px',
  textAlign: 'center',
  zIndex: 1000
};

const buttonStyle = {
  margin: '0 10px',
  padding: '10px 20px',
  backgroundColor: '#fff',
  color: '#000',
  border: 'none',
  cursor: 'pointer',
  marginTop:'20px',
};

export default CookieBanner;
