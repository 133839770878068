import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 

import Collection from '../Components/Collection/Collection'
import Offers from '../Components/Offers/Offers'
import Footer from '../Components/Footer/Footer'

const Shop = () => {
  const { t, i18n } = useTranslation(); // Utilise le hook pour accéder aux traductions
  const canonicalUrl = `${window.location.origin}/${i18n.language}/`;


  return (
    <div>
  <Helmet>
  <title>{t('seo.shop_title')}</title>
  <meta name="description" content={t('seo.shop_description')} />
  <meta name="keywords" content={t('seo.shop_keywords')} />
  <link rel="canonical" href={canonicalUrl} /> 
</Helmet>
        <Offers></Offers>
        <Collection></Collection>
        <Footer></Footer>
    </div>
  )
}

export default Shop
/*<Hero></Hero>*/

/*        
        */