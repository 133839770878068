import React from 'react'
import Footer from '../Components/Footer/Footer'
import MentionsDisplay from '../Components/MentionsDisplay/MentionsDisplay'

const MentionsLegales = () => {
  return (
    <div>
    <MentionsDisplay></MentionsDisplay>
    <Footer></Footer>
    </div>
  )
}

export default MentionsLegales