import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 
import FaqDisplay from '../Components/FaqDisplay/FaqDisplay'
import Footer from '../Components/Footer/Footer'

const Faq = () => {
  const { t, i18n } = useTranslation();
  const canonicalUrl = `${window.location.origin}/${i18n.language}/faq`;

  return (
    <div>
      <Helmet>
        <title>{t('seo.faq_title')}</title>
        <meta name="description" content={t('seo.faq_description')} />
        <meta name="keywords" content={t('seo.faq_keywords')} />
        <link rel="canonical" href={canonicalUrl} /> 
      </Helmet>
      <FaqDisplay></FaqDisplay>
      <Footer></Footer>
    </div>
  )
}

export default Faq