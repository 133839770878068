import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import './FaqDisplay.css';
import { Link } from 'react-router-dom'; // Utilisation de React Router pour gérer les liens

const FaqDisplay = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';
  const faqEntries = t('faq.entries', { returnObjects: true });

  const renderAnswer = (answer) => {
    // Sépare le texte avant et après le lien
    const parts = answer.split('<contact_link>');

    return (
      <span>
        {parts[0]}
        <Link to={`/${currentLang}/contact`}>{t('processValidation.here')}</Link>
        {parts[1]}
      </span>
    );
  };

  return (
    <Container maxWidth="md" className="faq-display-container">
      <Typography variant="h4" component="h1" gutterBottom>
        {t('faq.title')}
      </Typography>
      {faqEntries.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer.includes('<contact_link>') ? renderAnswer(faq.answer) : faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FaqDisplay;
