import React from 'react';
import './DescriptionBox.css';
import { useTranslation } from 'react-i18next';
import foot2 from '../Assets/allfoot01.jpg'
import pot01 from '../Assets/box_white01.jpg'
import whiteled from '../Assets/white_light1.jpg'
import redled from '../Assets/white_light2.jpg'
import footled from '../Assets/footled_blue1.jpg'

const DescriptionBox = () => {
  const { t } = useTranslation();

  return (
    <div className='descriptionbox'>
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">{t('descriptionBox.details')}</div>
      </div>
      <div className="descriptionbox-description">

        <h2>{t('descriptionBox.analogPotentiometers')}</h2>
        <p>{t('descriptionBox.analogPotentiometersDesc')}</p>
        <img src={pot01} alt="" className='descriptionbox-imgpot'/>
        
        <h2>{t('descriptionBox.midiChannelSelector')}</h2>
        <p>{t('descriptionBox.midiChannelSelectorDesc')}</p>
        <p>{t('descriptionBox.midiChannelSelectorDesc2')}</p>
        <p>{t('descriptionBox.midiChannelSelectorDesc3')}</p>
        <p>{t('descriptionBox.midiChannelSelectorDesc4')}</p>
        
        <div className="descriptionBox-carrouss">
        <img src={whiteled} alt="" className='descriptionbox-imgled'/><img src={redled} alt="" className='descriptionbox-imgled'/>
        </div>
        
        <p>{t('descriptionBox.midiChannelSelectorDesc5')}</p>
        <h2>{t('descriptionBox.jackport')}</h2>
        <p>{t('descriptionBox.jackportdesc')}</p>
        <h1>{t('descriptionBox.footswitch')}</h1>
        <hr/>
        <img src={foot2} alt="" className='descriptionbox-imgped'/>
        <h2>{t('descriptionBox.switchesWithLed')}</h2>
        <p>{t('descriptionBox.switchesWithLedDesc')}</p>
        <p>{t('descriptionBox.switchesWithLedDesc2')}</p>
        <img src={footled} alt="" className='descriptionbox-imgped'/>
        <h2>{t('descriptionBox.compatibility')}</h2>
        <p>{t('descriptionBox.cablesIncluded')}</p>
        <hr/>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/Jrk8Pwwozi8?si=o4iE66_ezniQTOqv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.neural')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/Wv-CrGBFg-U?si=hdZ4KozhxKWeWHFB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.amplitube')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/JDOIl7-Cdds?si=now4YGOvZO_FAQ1T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.fatblob')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/2U0lOXEfy5U?si=m8USP5lX6FZ6Rtzj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  
      </div>
    </div>
  );
};

export default DescriptionBox;
