import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem('auth-token');
  const location = useLocation();
  
  // Extraire la langue actuelle de l'URL
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en'; // 'en' par défaut si aucune langue n'est trouvée

  return isAuthenticated ? element : <Navigate to={`/${currentLang}/login`} />;
};

export default ProtectedRoute;
