import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 
import Footer from '../Components/Footer/Footer'
import AboutDisplay from '../Components/About/AboutDisplay'

const About = () => {
  const { t, i18n } = useTranslation(); // Utilise le hook pour accéder aux traductions
  const canonicalUrl = `${window.location.origin}/${i18n.language}/about`;

  return (
    <div>
        <Helmet>
        <title>{t('seo.about_title')}</title>
        <meta name="description" content={t('seo.about_description')} />
        <meta name="keywords" content={t('seo.about_keywords')} />
        <link rel="canonical" href={canonicalUrl} /> 
      </Helmet>
<AboutDisplay></AboutDisplay>
<Footer></Footer>
    </div>
  )
}

export default About